import bg1 from "../assets/images/background/bg-1.png"
import bg2 from "../assets/images/background/bg-2.png"
import bg3 from "../assets/images/background/bg-3.png"
import bg4 from "../assets/images/background/bg-4.png"

import bt_A1 from "../assets/images/beast/bt-1-1.png"
import bt_A2 from "../assets/images/beast/bt-1-2.png"
import bt_A3 from "../assets/images/beast/bt-1-3.png"
import bt_A4 from "../assets/images/beast/bt-1-4.png"
import bt_B1 from "../assets/images/beast/bt-2-1.png"
import bt_B2 from "../assets/images/beast/bt-2-2.png"
import bt_B3 from "../assets/images/beast/bt-2-3.png"
import bt_B4 from "../assets/images/beast/bt-2-4.png"
import bt_C1 from "../assets/images/beast/bt-3-1.png"
import bt_C2 from "../assets/images/beast/bt-3-2.png"
import bt_C3 from "../assets/images/beast/bt-3-3.png"
import bt_C4 from "../assets/images/beast/bt-3-4.png"
import bt_D1 from "../assets/images/beast/bt-4-1.png"
import bt_D2 from "../assets/images/beast/bt-4-2.png"
import bt_D3 from "../assets/images/beast/bt-4-3.png"
import bt_D4 from "../assets/images/beast/bt-4-4.png"
import bt_E1 from "../assets/images/beast/bt-5-1.png"
import bt_E2 from "../assets/images/beast/bt-5-2.png"
import bt_E3 from "../assets/images/beast/bt-5-3.png"
import bt_E4 from "../assets/images/beast/bt-5-4.png"

import astrolabe_1 from "../assets/images/astrolabe/1.png"
import astrolabe_2 from "../assets/images/astrolabe/2.png"
import astrolabe_3 from "../assets/images/astrolabe/3.png"
import astrolabe_4 from "../assets/images/astrolabe/4.png"
import astrolabe_5 from "../assets/images/astrolabe/5.png"
import astrolabe_6 from "../assets/images/astrolabe/6.png"
import astrolabe_7 from "../assets/images/astrolabe/7.png"
import astrolabe_8 from "../assets/images/astrolabe/8.png"
import astrolabe_9 from "../assets/images/astrolabe/9.png"
import astrolabe_10 from "../assets/images/astrolabe/10.png"
import astrolabe_11 from "../assets/images/astrolabe/11.png"
import astrolabe_12 from "../assets/images/astrolabe/12.png"

import stars_1 from "../assets/images/stars/1.png"
import stars_2 from "../assets/images/stars/2.png"
import stars_3 from "../assets/images/stars/3.png"
import stars_4 from "../assets/images/stars/4.png"
import stars_5 from "../assets/images/stars/5.png"
import stars_6 from "../assets/images/stars/6.png"
import stars_7 from "../assets/images/stars/7.png"
import stars_8 from "../assets/images/stars/8.png"
import stars_9 from "../assets/images/stars/9.png"
import stars_10 from "../assets/images/stars/10.png"
import stars_11 from "../assets/images/stars/11.png"
import stars_12 from "../assets/images/stars/12.png"
import stars_13 from "../assets/images/stars/13.png"

import icon_A1 from "../assets/images/icons/A1.png"
import icon_A2 from "../assets/images/icons/A2.png"
import icon_A3 from "../assets/images/icons/A3.png"
import icon_A4 from "../assets/images/icons/A4.png"
import icon_A5 from "../assets/images/icons/A5.png"
import icon_A6 from "../assets/images/icons/A6.png"
import icon_B1 from "../assets/images/icons/B1.png"
import icon_B2 from "../assets/images/icons/B2.png"
import icon_B3 from "../assets/images/icons/B3.png"
import icon_B4 from "../assets/images/icons/B4.png"
import icon_B5 from "../assets/images/icons/B5.png"
import icon_B6 from "../assets/images/icons/B6.png"
import icon_C1 from "../assets/images/icons/C1.png"
import icon_C2 from "../assets/images/icons/C2.png"
import icon_C3 from "../assets/images/icons/C3.png"
import icon_C4 from "../assets/images/icons/C4.png"
import icon_C5 from "../assets/images/icons/C5.png"
import icon_C6 from "../assets/images/icons/C6.png"
import icon_D1 from "../assets/images/icons/D1.png"
import icon_D2 from "../assets/images/icons/D2.png"
import icon_D3 from "../assets/images/icons/D3.png"
import icon_D4 from "../assets/images/icons/D4.png"
import icon_D5 from "../assets/images/icons/D5.png"
import icon_D6 from "../assets/images/icons/D6.png"
import icon_E1 from "../assets/images/icons/E1.png"
import icon_E2 from "../assets/images/icons/E2.png"
import icon_E3 from "../assets/images/icons/E3.png"
import icon_E4 from "../assets/images/icons/E4.png"
import icon_E5 from "../assets/images/icons/E5.png"
import icon_E6 from "../assets/images/icons/E6.png"

import card1 from "../assets/images/cards/1.png"
import card2 from "../assets/images/cards/2.png"
import card3 from "../assets/images/cards/3.png"
import card4 from "../assets/images/cards/4.png"
import card5 from "../assets/images/cards/5.png"
import card6 from "../assets/images/cards/6.png"
import card7 from "../assets/images/cards/7.png"
import card8 from "../assets/images/cards/8.png"
import card9 from "../assets/images/cards/9.png"
import card10 from "../assets/images/cards/10.png"
import card11 from "../assets/images/cards/11.png"
import card12 from "../assets/images/cards/12.png"
import card13 from "../assets/images/cards/13.png"
import card14 from "../assets/images/cards/14.png"
import card15 from "../assets/images/cards/15.png"

export const background = {
    'bg1': bg1,
    'bg2': bg2,
    'bg3': bg3,
    'bg4': bg4
}
export const beast = {
    'bt_A1': bt_A1,
    'bt_A2': bt_A2,
    'bt_A3': bt_A3,
    'bt_A4': bt_A4,
    'bt_B1': bt_B1,
    'bt_B2': bt_B2,
    'bt_B3': bt_B3,
    'bt_B4': bt_B4,
    'bt_C1': bt_C1,
    'bt_C2': bt_C2,
    'bt_C3': bt_C3,
    'bt_C4': bt_C4,
    'bt_D1': bt_D1,
    'bt_D2': bt_D2,
    'bt_D3': bt_D3,
    'bt_D4': bt_D4,
    'bt_E1': bt_E1,
    'bt_E2': bt_E2,
    'bt_E3': bt_E3,
    'bt_E4': bt_E4,
}

export const astrolabe = {
    '1': astrolabe_1,
    '2': astrolabe_2,
    '3': astrolabe_3,
    '4': astrolabe_4,
    '5': astrolabe_5,
    '6': astrolabe_6,
    '7': astrolabe_7,
    '8': astrolabe_8,
    '9': astrolabe_9,
    '10': astrolabe_10,
    '11': astrolabe_11,
    '12': astrolabe_12,
}
export const stars = {
    '1': stars_1,
    '2': stars_2,
    '3': stars_3,
    '4': stars_4,
    '5': stars_5,
    '6': stars_6,
    '7': stars_7,
    '8': stars_8,
    '9': stars_9,
    '10': stars_10,
    '11': stars_11,
    '12': stars_12,
    '13': stars_13,
}
export const icons = {
    'A1': icon_A1,
    'A2': icon_A2,
    'A3': icon_A3,
    'A4': icon_A4,
    'A5': icon_A5,
    'A6': icon_A6,
    'B1': icon_B1,
    'B2': icon_B2,
    'B3': icon_B3,
    'B4': icon_B4,
    'B5': icon_B5,
    'B6': icon_B6,
    'C1': icon_C1,
    'C2': icon_C2,
    'C3': icon_C3,
    'C4': icon_C4,
    'C5': icon_C5,
    'C6': icon_C6,
    'D1': icon_D1,
    'D2': icon_D2,
    'D3': icon_D3,
    'D4': icon_D4,
    'D5': icon_D5,
    'D6': icon_D6,
    'E1': icon_E1,
    'E2': icon_E2,
    'E3': icon_E3,
    'E4': icon_E4,
    'E5': icon_E5,
    'E6': icon_E6,
}
export const cardFronts = {
    'card1': card1,
    'card2': card2,
    'card3': card3,
    'card4': card4,
    'card5': card5,
    'card6': card6,
    'card7': card7,
    'card8': card8,
    'card9': card9,
    'card10': card10,
    'card11': card11,
    'card12': card12,
    'card13': card13,
    'card14': card14,
    'card15': card15,
}