import React, { useEffect, useRef } from "react"

import souvenirCard from '../assets/images/souvenir-card.png'
import iconRight from '../assets/images/icon-right.svg'
import { throttle } from "../utils/tool"



const Souvenir = React.forwardRef((props, ref) => {
    useEffect(() => {
        if (ref.current.offsetTop) {
            window.addEventListener('scroll', throttle(handleScroll, 100))
        }
    }, [ref])

    useEffect(() => {
        return(() => {
            window.removeEventListener('scroll')
        })
    }, [])

    function handleScroll() {
        if (ref.current) {
            const minRange = ref.current.offsetTop - souvenirBlankRef.current.offsetHeight - window.innerHeight / 2
            const maxRange = ref.current.offsetTop - window.innerHeight / 3
    
            // console.log(window.scrollY, minRange, maxRange)
            if ( window.scrollY > minRange && window.scrollY < maxRange ) {
                props.setLineDisplay(2)
            }
            else if (window.scrollY < minRange && window.scrollY > minRange - window.innerHeight) {
                props.setLineDisplay(false)
            }
            else if (window.scrollY < maxRange + window.innerHeight && window.scrollY > maxRange) {
                props.setLineDisplay(false)
            }
        }
    }


    function goPersonal() {
        event.preventDefault()
        document.location = `/personal?id=${inputRef.current.value}`
    }
    
    const souvenirBlankRef = useRef()
    const inputRef = useRef()

    return (
        <>
        <div className="blank" ref={souvenirBlankRef}></div>
        <div id="souvenir" className="block" ref={ref}>
            <div className="page-content">
                <div className="title">讀取紀念卡</div>
                <div className="card-area">
                    <img className="card" src={souvenirCard} />
                    <form onSubmit={goPersonal}>
                        <input type="text" ref={inputRef} placeholder="請輸入紀念卡編號" />
                        <button type="submit">
                            <img src={iconRight} />
                        </button>
                    </form>
                </div>
            </div>
        </div>
        </>
    )
})

export default Souvenir