import { useEffect, useRef, useState } from 'react'
import { background, beast, astrolabe, stars, icons } from '../utils/images'

import cardBack from '../assets/images/card-back.png'
import classNames from 'classnames'
import axios from 'axios'
import dayjs from 'dayjs'

export default function Personal() {

    const id = new URL(window.location).searchParams.get("id")
    const [ record, setRecord ] = useState(null)
    const [ iconDisplay, setIconDisplay ] = useState(false)

    useEffect(() => {
        getResult()
        window.addEventListener('scroll', () => {
            const cardBackHeight = cardBackRef.current.height
            if (window.scrollY > cardBackHeight / 3) {
                setIconDisplay(true)
            }
            else {
                setIconDisplay(false)
            }
        })
        return(() => {
            window.removeEventListener('scroll')
        })
    }, [])

    function getResult() {
        axios.get(`/api/result/detail/${id}`)
        .then((res) => {
            console.log('res', res.data)
            setRecord(res.data)
        })
        .catch((err) => {
            console.log('err', err.response)

            if (err.response.status == 404) {
                setRecord('not-found')
            }
            else {
                setRecord('error')
            }
        })
    }

    function handleTime() {
        const dayTime = record.start_time.slice(0,2) > 12 ? '下午' : '上午'
        return dayjs(`${record.date}${record.start_time}`).format(`YYYY 年 MM 月 D 日 ${dayTime} h 點 m 分`)
    }
    function handleStars() {
        const group = record.tracker_shortcut.slice(0, 1)
        const num = record.tracker_shortcut.slice(1, 2)
        const groupNum = {
            'A': 0,
            'B': 1,
            'C': 2,
            'D': 3,
            'E': 4,
        }
        const total = groupNum[group] * 5 + Number(num)
        const times = Math.floor(total / 13)
        const finalNum = total - times * 13
        return finalNum === 0 ? 13 : finalNum
    }

    const cardBackRef = useRef()
    const iconClass = classNames('icon', {
        'show': iconDisplay
    })

    return (
        <>
        { record && record !== 'not-found' && record !== 'error' &&
            <div id="personal" className="block">
                <div className="page-content">

                    <div className="title title-1">{id}</div>
                    <div className="card-area">
                        <img src={cardBack} ref={cardBackRef} className="card-back" />
                        <img className={iconClass} src={icons[record.tracker_shortcut]} />
                    </div>

                    <div className="sub-title">{record.card_title}</div>
                    <p>{record.card_content}</p>
                    <div className="date">{handleTime()}</div>

                    <div className="title title-2">星遊紀錄</div>
                    <p>{record.background_description}</p>
                    <img src={background[`bg${record.background_code}`]} className="scene" />

                    <div className="title title-3">你的守護星獸</div>
                    <p>{record.beast_description}</p>
                    <p className="second">{record.beast_pose_description}</p>
                    <img src={beast[`bt_${record.beast_code}`]} className="mythical-beast" />

                    <div className="title title-4">當日觀星盤</div>
                    <img src={astrolabe[record.astrolabe]} className="astrolabe" />
                </div>
            </div>
        }
        { (record === 'not-found' || record === 'error') &&
            <div id="personal" className="block not-found">
                <div className="page-content">
                    <div className="content-none">
                        { record === 'not-found' &&
                            <>
                            <div className="title-none">沒有找到你的星球</div>
                            <div className="sub-title-none">可能還在星空飛行，建議稍後再試。</div>
                            </>
                        }
                        { record === 'error' &&
                            <div className="third-title-none">似乎出了一點問題，請稍後再試一次。</div>
                        }
                    </div>
                    <img src={cardBack} className="card-back" />
                </div>
            </div>
        }
        </>
    )
}