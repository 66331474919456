import classNames from "classnames"
import { React, useEffect, useRef } from "react"

import line1 from '../assets/images/line-1.png'
import backgroundVideo from 'url:../assets/video/background.mp4'

export default function Background(props) {

    const videoTimeout = useRef()


    useEffect(() => {
        videoRef.current.addEventListener('loadeddata', () => {
            videoTimeout.current = setTimeout(() => {
                props.setIsLoading(false)
            }, [500])
        }, false)

        return(() => {
            videoRef.current.removeEventListener('loaddata', false)
            clearTimeout(videoTimeout.current)
        })
    }, [])

    useEffect(() => {
        if (!props.isLoading) {
            if (videoRef.current.paused === true) {
                videoRef.current.play()
            }
        }
    }, [props.isLoading])



    const videoRef = useRef()
    const bgClass = classNames('bg', {
        'dark': props.bgDark
    })
    const lineClass = classNames('line', `line-${props.lineDisplay}`)

    return (
        <div className="background">
                <video
                    className={bgClass}
                    ref={videoRef}
                    autoPlay={true} loop={true} playsInline={true} muted={true}
                >
                    <source src={backgroundVideo} type="video/mp4" />
                </video>

            {/* { (props.lineDisplay == 1 || props.lineDisplay == 2) &&
                <css-doodle class={`line-${props.lineDisplay}`}>
                    @grid: 20x1 / 100%;
                    grid-row-gap: 1rem;
                    @size: 150vw 75vw;
                    border-radius: 30vw;
                    @place: center;
                    border-top: 1px solid rgba(237, 186, 109, calc(100% - @i%));
                    transform: rotate(calc(@i * 7deg)) translateX(calc(70% - @i%));
                    opacity: 0;
                    transition: transform 3s linear, opacity 2.25s 0.75s;
                </css-doodle>
            } */}
            <img className={lineClass} src={line1} />

        </div>
    )
}