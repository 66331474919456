import React from "react"

import iconMap from '../assets/images/icon-map.svg'

const Traffic = React.forwardRef((props, ref) => {
    
    return (
        <div id="traffic" className="block" ref={ref}>
            <div className="page-content">
                <div className="top-area">
                    <div className="left-area">
                        <div className="content">
                            <div className="list-title">劇場位置：鳶峰遊客中心</div>
                            <ul>
                                <li>地址：南投縣仁愛鄉台14甲線24.3公里處</li>
                                <li>附停車場、洗手間、觀景平台</li>
                            </ul>
                        </div>
                        <div className="content">
                            <div className="list-title">
                                {/* <img src={iconMap} /> */}
                                大眾運輸
                            </div>
                            <p>（從台中高鐵站出發）</p>
                            <ul>
                                <li>於台中干城車站/台中高鐵5號出口，搭乘南投客運6670公車至埔里</li>
                                <li>轉乘南投客運6664號公車於國民賓館站下車</li>
                                <li>再轉乘6658A南投客運至鳶峰站下車</li>
                            </ul>
                        </div>
                    </div>
                    <div className="right-area">
                        <iframe src="https://snazzymaps.com/embed/439270"></iframe>
                    </div>
                </div>
                {/* <div className="content content-weather">
                    <div className="title">即時天氣</div>
                    <div className="weather-area">天氣狀況</div>
                </div>
                <div className="content content-stargazing">
                    <div className="title">其他觀星資訊</div>
                    <div className="stargazing-area">未定</div>
                </div> */}
            </div>
        </div>
    )
})

export default Traffic