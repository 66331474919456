import { useEffect, useState } from "react"
import { scrollTo } from "../utils/tool"

import logoSm from '../assets/images/logo-sm.png'
import classNames from "classnames"
export default function Navbar(props) {

    const [ toggle, setToggle ] = useState(false)
    const [ logoSmDisplay, setLogoSmDisplay ] = useState(false)
    const [ mobile, setMobile ] = useState(false)
    // const bannerRef = props.bannerRef
    const introRef = props.introRef
    const introPictureRef = props.introPictureRef
    const infoRef = props.infoRef
    // const trafficRef = props.trafficRef
    const souvenirRef = props.souvenirRef

    const persoalPage = document.location.pathname.indexOf('personal') !== -1


    useEffect(() => {
        if (document.location.pathname.indexOf('personal') !== -1) {
            setLogoSmDisplay(true)
        }
        else if (props.logoLgTop) {
            window.addEventListener('scroll', () => {
                const logoLgTop = props.logoLgTop.clientHeight + props.logoLgTop.offsetTop
                if (window.scrollY > logoLgTop + 100) {
                    setLogoSmDisplay(true)
                }
                else {
                    setLogoSmDisplay(false)
                }
            })
        }
    }, [props.logoLgTop])

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth < 900) {
                setMobile(true)
            }
            else {
                setMobile(false)
            }
        })
        return(() => {
            window.removeEventListener('scroll')
            window.removeEventListener('resize')
        })
    }, [])

    
    const navBarClass = classNames('Navbar', {
        'toggle': toggle,
        'mobile': mobile
    })
    const logoSmClass = classNames('logo-sm', {
        'show': logoSmDisplay
    })

    return (
        <>
        {
            window.innerWidth >= 900
            ?
            <div className={navBarClass}>
                <div className="page-content">
                    <div className={logoSmClass}>
                        { !persoalPage
                            ?
                            <>
                            <button onClick={() => {scrollTo(introRef.current.offsetTop)}}>
                                <img src={logoSm} />
                            </button>
                            </>
                            :
                            <>
                            <button>
                                <a href="/">
                                    <img src={logoSm} />
                                </a> 
                            </button>
                            </>
                        }
                    </div>
                    <div className="nav-item">
                        { !persoalPage
                            ?
                            <>
                            <button onClick={() => {scrollTo(introPictureRef.current.offsetTop)}}>劇場簡介</button>
                            <button onClick={() => {scrollTo(infoRef.current.offsetTop)}}>參觀資訊</button>
                            {/* <button onClick={() => {scrollTo(trafficRef.current.offsetTop)}}>交通方式</button> */}
                            <button onClick={() => {scrollTo(souvenirRef.current.offsetTop)}}>搜尋紀念卡</button>
                            </>
                            :
                            <>
                            <button><a href="/#intro-picture">劇場簡介</a></button>
                            <button><a href="/#info">參觀資訊</a></button>
                            {/* <button><a href="/#traffic">交通方式</a></button> */}
                            <button><a href="/#souvenir">搜尋紀念卡</a></button>
                            </>
                        }
                        <button><a href="https://youtu.be/SX2tqzmOUbc" target="_blank">星空直播</a></button>
                    </div>
                </div>
            </div>
            :
            <div className={navBarClass}>
                <div className="mobile">
                    <button className="toggle" onClick={() => {setToggle(!toggle)}}>
                        <div class="lines"></div>
                    </button>
                    <div className="nav-item">
                        { !persoalPage
                            ?
                            <>
                            <button
                                onClick={() => {
                                    scrollTo(introRef.current.offsetTop)
                                    setToggle(!toggle)
                                }}
                            ><img class="logo" src={logoSm} /></button>
                            <button
                                onClick={() => {
                                    scrollTo(introPictureRef.current.offsetTop)
                                    setToggle(!toggle)
                                }}
                            >劇場簡介</button>
                            <button
                                onClick={() => {
                                    scrollTo(infoRef.current.offsetTop)
                                    setToggle(!toggle)
                                }}
                            >參觀資訊</button>
                            {/* <button
                                onClick={() => {
                                    scrollTo(trafficRef.current.offsetTop)
                                    setToggle(!toggle)
                                }}
                            >交通方式</button> */}
                            <button
                                onClick={() => {
                                    scrollTo(souvenirRef.current.offsetTop)
                                    setToggle(!toggle)
                                }}
                            >搜尋紀念卡</button>
                            </>
                            :
                            <>
                            <button><a href="/#intro-picture">劇場簡介</a></button>
                            <button><a href="/#info">參觀資訊</a></button>
                            {/* <button><a href="/#traffic">交通方式</a></button> */}
                            <button><a href="/#souvenir">搜尋紀念卡</a></button>
                            </>
                        }
                        <button><a href="https://youtu.be/SX2tqzmOUbc" target="_blank">星空直播</a></button>
                    </div>
                </div>
            </div>
        }
        </>
    )
}