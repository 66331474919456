import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import CSSDoodle from "css-doodle"

import { throttle } from "../utils/tool"
import { cardFronts } from "../utils/images"
const basicScroll = require('basicscroll')
import * as basicScroll from 'basicscroll'

import cardBack from '../assets/images/card-back-scale.png'


const Intro = React.forwardRef((props, ref) => {
    const [ random, setRandom ] = useState(Math.floor(Math.random() * 9) + 1)
    const [ cardNum, setCardNum] = useState(9)
    const [ cards, setCards ] = useState([])
    const [ activeCards, setActiveCards ] = useState(null)
    const cardsRef = useRef()

    useEffect(() => {
        handleCardNum()
        window.addEventListener('scroll', throttle(() => {setActiveCards(null)}, 300))
        window.addEventListener('resize', handleCardNum)

        return(() => {
            window.removeEventListener('resize')
            window.removeEventListener('scroll')
        })
    }, [])

    // useEffect(() => {
    //     window.addEventListener('scroll', throttle(handleScroll, 100))
    // }, [cardNum])
    
    useEffect(() => {
        handleCards()
    }, [activeCards, cardNum])

    useEffect(() => {
        if (cards.length > 0) {
            handleScaleCard()
        }
    }, [cards])

    // useEffect(() => {
    //     if (scrollInto) {
    //         setActiveCards(`card-${random}`)
    //     }
    //     else {
    //         setActiveCards(null)
    //     }
    // }, [scrollInto])

    function handleCardNum() {
        if (document.body.offsetWidth < 900) {
            setCardNum(1)
        }
        else {
            setCardNum(9)
        }
    }
    function handleCards() {
        const list = []
        for (let i = 0; i < cardNum; i++) {
            const cardName = `card-${i + 1}`
            const cardClass = classNames('card', cardName, {
                'random': random - 1 == i,
                'active': activeCards === cardName,
                'default': activeCards !== cardName,
                'back': i <= 3,
                'mobile': cardNum === 1
            })
            if (activeCards && cardsRef.current.children[i]) {
                if (window.innerWidth < 900) {
                    cardsRef.current.children[i].style = null
                    cardsRef.current.children[i].children[0].style = null
                }
            }

            list.push(
                <div
                    key={`card-${i}`}
                    className={cardClass}
                    // onClick={() => {handleCardClick(cardName)}}
                    onClick={() => { window.innerWidth >= 900 && setActiveCards(cardName)} }
                >   
                    { activeCards === cardName &&
                        <>
                            { window.innerWidth >= 900
                                ?
                                    <css-doodle>
                                        @grid: 10 / 8vw;
                                        @shape: star;
                                        background: #EDBA6D;
                                        transform:
                                            scale(@rand(.2, .5, .6, .3, .4))
                                            rotate(@rand(360)deg)
                                            translateX(-20vw);
                                        opacity: 0;
                                        transition: all 2s, opacity 4s 1s;
                                    </css-doodle>
                                :
                                    <css-doodle>
                                        @grid: 10 / 40vw;
                                        @shape: star;
                                        background: #EDBA6D;
                                        transform:
                                            scale(@rand(.2, .5, .6, .3, .4))
                                            rotate(@rand(360)deg)
                                            translateX(-80vw);
                                        opacity: 0;
                                        transition: all 2s, opacity 4s 1s;
                                    </css-doodle>
                            }
                        </>
                    }
                    <div className="cover"></div>
                </div>
            )
        }
        setCards(list)
    }

    function handleScaleCard() {
        let card = cardNum === 1 ? cardsRef.current.children[0] : cardsRef.current.children[random - 1]
        const instance = basicScroll.create({
            elem: card,
            from: 'top-middle',
            to: 'bottom-middle',
            inside: (instance, percentage, props) => {
                card.children[0].style.backgroundImage = 
                    percentage > 50 ? `url("${cardFronts['card' + random]}")` : `url("${cardBack}")` 
            },
            props: {
                '--r': { from: '-1', to: '1' }
            }
        })
        instance.start()
        setTimeout(() => {
            instance.calculate()
            instance.update()
        }, [100])
    }


    function handleScroll() {
        const currentScroll = window.scrollY
        const cardsPosition = cardsRef.current.parentElement.offsetTop
        const cardsHeight = cardsRef.current.offsetHeight

        if (currentScroll > cardsPosition - (cardsHeight / 2)) {
            if (!activeCards) {
                let card = cardNum === 1 ? cardsRef.current.children[0] : cardsRef.current.children[random - 1]
                const rotate = 1 - (currentScroll - cardsPosition + ((cardsHeight / 2))) * 0.005
                if (rotate > -1 && rotate < 1 && card) {
                    card.style.zIndex = 2
                    // console.log(rotate)
                    if (rotate < 0) {
                        card.style.transform = `scaleX(${-rotate})`
                        card.children[0].style.backgroundImage =  `url("${cardFronts['card' + random]}")`
                    }
                    else {
                        card.style.transform = `scaleX(${rotate})`
                        card.children[0].style.backgroundImage =  `url("${cardBack}")` 
                    }
                }
            }
        }
    }

    // function handleScroll() {
    //     const currentScroll = window.scrollY
    //     const cardsPosition = cardsRef.current.parentElement.offsetTop
    //     const cardsHeight = cardsRef.current.offsetHeight
        
    //     if (currentScroll > cardsPosition - (cardsHeight / 2)) {
    //         if (!activeCards) {
    //             setScrollInto(true)
    //         }
    //     }
    //     else {
    //         setScrollInto(false)
    //     }
    // }

    return (
        <div className="Cards" ref={cardsRef}>
            {cards}
        </div>
    )
})

export default Intro