import React, { useEffect, useRef, useState } from "react"
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import testPic from '../assets/images/test.jpg'
import starFinding from '../assets/images/star-finding.png'
import carousel1 from '../assets/images/carousel-1.png'
import carousel2 from '../assets/images/carousel-2.png'
import carousel3 from '../assets/images/carousel-3.png'
import carousel4 from '../assets/images/carousel-4.png'
import Cards from "../component/Cards"
import logoLg from '../assets/images/logo-lg.png'
import videoCover from '../assets/images/video-cover.png'

import videoBorder from '../assets/images/video-border.png'
import backgroundVideo from 'url:../assets/video/background.mp4'
import classNames from "classnames";
import { throttle } from "../utils/tool";

const imageList = [
    carousel1, carousel2, carousel3, carousel4
]
const introList = [
    {
        "title": "是專業星象儀，也是互動劇場",
        "content": "星象儀圓頂式天幕投影，除了天文解說，還能帶給你沉浸式的動畫故事體驗。",
    },
    {
        "title": "不只是動畫，你也參與其中",
        "content": "透過互動，找出今夜在鳶峰的星座，不同時機來訪有不同驚喜！",
    },
    {
        "title": "你的思考，將為你創造獨一無二的回憶",
        "content": "進場的小選擇，與你身旁的夥伴合作，將決定你今天到此一遊的紀念內容。",
    },
    {
        "title": "完全在地，別的地方看不到的內容",
        "content": "一段結合在地文化、地景與生物的天文奇幻旅程，尋找夜空中屬於自己的那顆星......",
    }
]

const Intro = React.forwardRef((props, ref) => {

    const [ images, setImages ] = useState([])
    const [ popupDisplay, setPopupDisplay ] = useState(false)
    const [ iframeSrc, setIframeSrc ] = useState(null)
 
    useEffect(() => {
        handleImages()
        props.setLogoLgTop(logoLgRef.current)
        props.setIntroVideoRef(introVideoRef)
        props.setIntroPictureRef(introPictureRef)
        window.addEventListener('scroll', throttle(handleScroll, 100))

        return(() => {
            window.removeEventListener('scroll')
        })
    }, [])

    useEffect(() => {
        if (popupDisplay === true) {
            setIframeSrc("https://player.vimeo.com/video/774084388?h=0767f60175&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479")
        }
        else {
            setIframeSrc(null)
        }
    }, [popupDisplay])

    function handleScroll() {
        // console.log('scol', introContentRef.current.offsetHeight, window.scrollY)
        if (window.scrollY > introContentRef.current.offsetHeight / 2) {
            props.setBgDark(true)
        }
        else {
            props.setBgDark(false)
        }

        // const minRange = introContentRef.current.offsetHeight + introVideoRef.current.offsetHeight / 2
        const minRange = introContentRef.current.offsetHeight
        const maxRange =
            introContentRef.current.offsetHeight + introVideoRef.current.offsetHeight + introBlankRef.current.offsetHeight / 2
        // console.log(window.scrollY, minRange, maxRange)
        if ( window.scrollY > minRange && window.scrollY < maxRange ) {
            props.setLineDisplay(1)
        }
        else if (window.scrollY < minRange && window.scrollY > minRange - window.innerHeight) {
            props.setLineDisplay(false)
        }
        else if (window.scrollY < maxRange + window.innerHeight && window.scrollY > maxRange) {
            props.setLineDisplay(false)
        }
    }

    function handleImages() {
        let list = []
        for (const [index, image] of imageList.entries()) {
            list.push(
                <div key={`image-${index}`}>
                    <img src={image} />
                    <div className="intro">
                        {/* <div className="img-title">觀星導覽第一站</div>
                        <p className="img-content">鳶峰遊客中心翻新，結合餐飲與劇場體驗，宣導暗空素養、發揚鳶峰夜晚旅程的驚喜體驗。</p> */}

                        <div className="img-title">{introList[index].title}</div>
                        <p className="img-content">{introList[index].content}</p>     
                    </div>
                </div>
            )
        }  
        setImages(list)
    }

    function handlePopupClose() {
        if (event.target.className.indexOf('popup') !== -1) {
            setPopupDisplay(false)
        }
    }


    const logoLgRef = useRef(null)
    const introContentRef = useRef(null)
    const introVideoRef = useRef(null)
    const introPictureRef = useRef(null)
    const introBlankRef = useRef(null)

    const popupClass = classNames('popup', {
        'show': popupDisplay
    })

    return (
        <div id="intro" className="block" ref={ref}>
            <div className="page-content">
                <div className="content content-intro" ref={introContentRef}>
                    <img src={logoLg} ref={logoLgRef} className="logo-lg" />
                    <p>台灣第一個獲得IDA國際認證的暗空公園裡</p>
                    <p>誕生了全台最高的沉浸式互動劇場 - 鳶峰星空劇場</p>
                    <p>在此，你將深入山林、俯瞰深谷、探索星空...</p>
                    <p></p>
                    <p>準備好了嗎？</p>
                    <p>關閉你的手機和手電筒，</p>
                    <p>在與漫天的星光會面之前，</p>
                    <p>讓我們向你演出奇幻的星遊序曲 ......</p>
                </div>

                <div id="intro-video" className="content content-video" ref={introVideoRef}>
                    <div className="title">現正播映</div>
                    <div className="video-area">
                        <img src={videoCover} className="video-cover" />
                        <div className="video-intro">
                            <div className="video-block">
                                <div className="video-title">片名</div>
                                <div className="video-name">尋找守護星</div>
                                <div className="video-sub-name">星獸傳奇</div>
                            </div>
                            <div className="video-block">
                                <div className="video-title">簡介</div>
                                <div className="video-content">
                                    <p>夜夢是自我探索的過程。</p>
                                    <p>星空是與星星對話的畫布。</p>
                                    <p>只要喚醒身上的能量，每個人都具有獨特的光芒</p>
                                    <p>成為照亮別人的存在。</p>
                                    <p>勇敢無懼的去追夢吧 ! </p>
                                    <p>「我們都是天上獨一無二的星星。」</p>
                                </div>
                            </div>
                            <div className="video-block">
                                <div className="video-title">片長：20分鐘</div>
                                <button onClick={() => {setPopupDisplay(true)}}>點我看預告</button>
                            </div>
                        </div>
                    </div>

                    <div className={popupClass} onClick={() => {handlePopupClose()}}>
                        <div className="page-content">
                            <button className="close-button" onClick={() => {setPopupDisplay(false)}}>✕</button>
                            <iframe
                                src={iframeSrc}
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>

                <div className="blank blank-1" ref={introBlankRef}></div>

                <div id="intro-picture" className="content content-picture" ref={introPictureRef}>
                    <div className="title">劇場簡介</div>

                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                    >
                        {images}
                    </Carousel>
                    <div className="start-finding">
                        <div className="intro">
                            <div className="img-title">尋星鏡</div>
                            <p className="img-content">科技定位道具 x 手工打造木製握柄</p>
                            <p className="img-content">蘊含天文與星座密碼的互動裝置</p>
                        </div>
                        <img className="mirror" src={starFinding} />
                    </div>
                </div>

                <div className="content content-card">
                    <div className="sub-title">體驗紀念卡牌</div>
                    <p>「我們都是星塵的化身」</p>
                    <p>勇敢成為最獨特的那顆星星</p>
                    <p>讓守護神獸帶領你探索未知，在最開始，直到結束。</p>
                    <p>隨著進入劇場的時間、互動橋段的參與程度，</p>
                    <p>守護神獸將以不同的樣貌成為你獨一無二的紀念。</p>
                    <Cards />
                </div>
            </div>
        </div>
    )
})

export default Intro