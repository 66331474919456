import { React, createRef, useEffect, useState, useRef } from "react"
import { BrowserRouter as Router, Switch, Route} from "react-router-dom"

import Personal from "./page/Personal"

import Loading from "./component/Loading"
import Background from "./component/Background"
import Planets from "./component/Planets"
import Navbar from "./component/Navbar"
import Footer from "./component/Footer"

// import Banner from './block/Banner'
import Intro from './block/Intro'
import Info from './block/Info'
import Traffic from './block/Traffic'
import Souvenir from './block/Souvenir'
import { scrollIntoBlock, throttle } from "./utils/tool"


function App() {

    const bannerRef = createRef()
    const introRef = createRef()
    const infoRef = createRef()
    const trafficRef = createRef()
    const souvenirRef = createRef()
    const lineTimeout = useRef()
    const loadingTimeout = useRef()
    const [ introVideoRef, setIntroVideoRef ] = useState(null)
    const [ introPictureRef, setIntroPictureRef ] = useState(null)
    const [ logoLgTop, setLogoLgTop ] = useState(null)
    const [ bgDark, setBgDark ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(true) 
    const [ lineDisplay, setLineDisplay ] = useState(false)

    const blocks = [introRef, introVideoRef, introPictureRef, infoRef, trafficRef, souvenirRef]

    useEffect(() => {
        if (introPictureRef && introVideoRef) {
            window.addEventListener('scroll', throttle(() => {scrollIntoBlock(blocks)}, 100))
        }
    }, [blocks])

    useEffect(() => {
        // console.log('lineDisplay', lineDisplay)
        clearTimeout(lineTimeout.current)
        if (lineDisplay !== false) {
            lineTimeout.current = setTimeout(() => {
                setLineDisplay(false)
            }, [2500])
        }
    }, [lineDisplay])

    useEffect(() => {
        clearTimeout(loadingTimeout.current)
        if (isLoading === true) {
            loadingTimeout.current = setTimeout(() => {
                setIsLoading(false)
            }, [3000])
        }
    }, [isLoading])

    useEffect(() => {
        return(() => {
            window.removeEventListener('scroll')
        })
    }, [])

    return (
        <Router>
            { isLoading && <Loading /> }
            <Switch>
                <Route path="/personal">
                    <Background
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />
                    <Navbar
                        // bannerRef={bannerRef}
                        introRef={introRef}
                        introPictureRef={introPictureRef}
                        infoRef={infoRef}
                        trafficRef={trafficRef}
                        souvenirRef={souvenirRef}
                        logoLgTop={logoLgTop}
                    />
                    <Personal />
                    <Footer />
                </Route>
                <Route path="/">

                    <Background
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        bgDark={bgDark}
                        lineDisplay={lineDisplay}
                    />
                    <Planets />

                    <Navbar
                        // bannerRef={bannerRef}
                        introRef={introRef}
                        introPictureRef={introPictureRef}
                        infoRef={infoRef}
                        trafficRef={trafficRef}
                        souvenirRef={souvenirRef}
                        logoLgTop={logoLgTop}
                    />
                    {/* <Banner ref={bannerRef}/> */}

                    <Intro
                        ref={introRef}
                        setLogoLgTop={setLogoLgTop}
                        setIntroVideoRef={setIntroVideoRef}
                        setIntroPictureRef={setIntroPictureRef}
                        setBgDark={setBgDark}
                        lineDisplay={lineDisplay}
                        setLineDisplay={setLineDisplay}
                    />
                    <Info ref={infoRef} />
                    <Traffic ref={trafficRef}/>
                    <Souvenir
                        ref={souvenirRef}
                        setLineDisplay={setLineDisplay}
                    />

                    <Footer
                        introRef={introRef}
                        introPictureRef={introPictureRef}
                        introVideoRef={introVideoRef}
                        infoRef={infoRef}
                        trafficRef={trafficRef}
                        souvenirRef={souvenirRef}
                    />

                </Route>
            </Switch>
        </Router>
    )
}

export default App;
