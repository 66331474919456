
import logoLg from '../assets/images/logo-lg.png'
import { scrollTo } from '../utils/tool'

export default function Footer(props) {

    const introRef = props.introRef
    const introVideoRef = props.introVideoRef
    const introPictureRef = props.introPictureRef
    const infoRef = props.infoRef
    // const trafficRef = props.trafficRef
    const souvenirRef = props.souvenirRef
    const persoalPage = document.location.pathname.indexOf('personal') !== -1

    return (
        <div className="Footer">
            <div className="page-content">
                <div className="content">
                    <img
                        className="logo-lg"
                        src={logoLg}
                        onClick={() => {scrollTo(introRef.current.offsetTop)}}
                    />
                    <div className="list-area">
                        <ul>
                            { !persoalPage
                            ?
                            <>
                            <li><button onClick={() => {scrollTo(introPictureRef.current.offsetTop)}}>劇場簡介</button></li>
                            <li><button onClick={() => {scrollTo(introVideoRef.current.offsetTop)}}>播映作品</button></li>
                            <li><button onClick={() => {scrollTo(infoRef.current.offsetTop)}}>參觀方式</button></li>
                            {/* <li><button onClick={() => {scrollTo(trafficRef.current.offsetTop)}}>交通資訊</button></li> */}
                            <li><button onClick={() => {scrollTo(souvenirRef.current.offsetTop)}}>搜尋紀念卡</button></li>
                            </>
                            :
                            <>
                            <li><a href="/#intro-picture">劇場簡介</a></li>
                            <li><a href="/#intro-video">播映作品</a></li>
                            <li><a href="/#info">參觀方式</a></li>
                            {/* <li><a href="/#traffic">交通資訊</a></li> */}
                            <li><a href="/#souvenir">搜尋紀念卡</a></li>
                            </>
                        }
                        </ul>
                        <ul>
                            <li>鳶峰星空劇場</li>
                            <li>南投縣仁愛鄉台14甲線24.3公里處</li>
                            {/* <li><a href="#">服務電話</a></li>
                            <li><a href="#">服務信箱</a></li>
                            <li><a href="#">預約方式</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}