import React, { useEffect, useState } from "react"

import iconPrice from '../assets/images/icon-price.svg'
import iconTime from '../assets/images/icon-time.svg'

const sessionList = [
    { "holiday": true, "time": "10:30" },
    { "holiday": false, "time": "11:30" },
    { "holiday": false, "time": "14:00" },
    { "holiday": false, "time": "15:00" },
    { "holiday": false, "time": "16:00" },
    { "holiday": true, "time": "17:00" },
    { "holiday": true, "time": "18:00" },
    { "holiday": true, "time": "19:00" },
]
const Info = React.forwardRef((props, ref) => {

    const [sessions, setSessions] = useState([])

    useEffect(() => {
        let list = []
        for (const session of sessionList) {
            list.push(
                <div className="session" key={`session-${session.time}`}>
                    <span>{session.time}</span>
                    <span>{session.holiday ? '例假日加開' : ''}</span>
                </div>
            )
        }
        setSessions(list)
    }, [])

    return (
        <div id="info" className="block" ref={ref}>
            <div className="page-content">
                <div className="content content-0">
                    <div className="title">參觀資訊</div>
                </div>
                {/* <div className="content content-session">
                    <div className="title">
                        <img src={iconTime}/> 場次
                    </div>
                    <div className="sessions">
                        {sessions}
                    </div>
                </div>

                <div className="content content-price">
                    <div className="title">
                        <img src={iconPrice}/> 票價
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>票價</th>
                                <th>說明</th>
                                <th>金額</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>全票</td>
                                <td>全票</td>
                                <td>250 元</td>
                            </tr>
                            <tr>
                                <td>學生票</td>
                                <td>持教育部立案大、中、小學學生證</td>
                                <td>200 元</td>
                            </tr>
                            <tr>
                                <td>鄉親票</td>
                                <td>身分證居住地為南投縣</td>
                                <td>200 元</td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}

                <div className="content content-1">
                    <div className="list-title">劇場體驗方式</div>
                    <ul>
                        <li>劇場一次 20 分鐘，包含總計約 10 分鐘之圓頂動畫劇場與 10 分鐘之數位互動。</li>
                        <li>每張票券包含一只數位互動道具租賃「尋星鏡」，可於劇場之互動環節參與劇情的發展。</li>
                        <li>每張票券可於劇場結束後，憑道具之歸還兌換劇場體驗紀念卡。</li>
                        <li>紀念卡印製外觀視當日、當場次劇情走向以及體驗者之參與情況而有所不同。</li>
                    </ul>
                </div>

                <div className="content content-2">
                    <div className="list-title">注意事項</div>
                    <ul>
                        {/* <li>每週一休館。</li> */}
                        <li>6 歲以下幼童觀賞須由家長陪同。</li>
                        <li>因本劇場可能包含環景、閃光、或其他可能導致暈眩感受之畫面，請注意身體狀況斟酌購票。</li>
                        <li>互動道具「尋星鏡」請遵照劇場內指引方式使用，如錯誤使用導致損毀、遺失、故障等情形須依造價賠償。</li>
                    </ul>
                </div>
            </div>
        </div>
    )
})

export default Info