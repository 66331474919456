import { React, useEffect, useState } from "react"

export default function Background() {

    const [ scrollY, setScrollY ] = useState(window.scrollY)
    const [ planets, setPlanets ] = useState([])

    function getScrollY() {
        setScrollY(window.scrollY)
    }

    function handlePlanets() {
        let list = []
        for (let i = 0; i < 5; i++) {
            const planetClass = `planet planet-${i + 1}`
            const speed = [0.2, -0.1, -0.05, -0.1, -0.25]
            const planetStyle = {
                transform: `translateY(${scrollY * speed[i]}px)`
            }
            list.push(
                <div
                    key={`planet-${i}`}
                    className={planetClass}
                    style={planetStyle}
                ></div>
            )
        }
        setPlanets(list)
    }

    useEffect(() => {
        handlePlanets()
        window.addEventListener('scroll', getScrollY)
    }, [])

    useEffect(() => {
        handlePlanets()
    }, [scrollY])

    useEffect(() => {
        return(() => {
            window.removeEventListener('scroll')
        })
    }, [])

    return (
        <div className="planets">
            {planets}
        </div>
    )
}