import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

export const smoothScroll = (target) => {
    
    let speed
    const currentScroll = document.body.scrollTop
    const dis = Math.abs(window.scrollY - target.offsetTop)
    speed = dis > 2000 ? 300 : 150
    speed = window.scrollY > target.offsetTop ? -(speed) : speed

    function scroll() {
        let targetPosition = target.getBoundingClientRect().top + currentScroll
        let distanceToTarget = targetPosition - currentScroll
        if (speed < 0) {
            distanceToTarget = currentScroll - targetPosition
        }
        if (distanceToTarget < speed) {
            target.scrollIntoView()
            window.scrollBy(0, -87)
        }
        else {
            window.scrollBy(0, speed)

            if (distanceToTarget == 0) return
            requestAnimationFrame(scroll)
        }
    }
    scroll()
}

export const autoScroll = (target, speed) => {
    
    const currentScroll = document.body.scrollTop

    function scroll() {
        let targetPosition = target.getBoundingClientRect().top + currentScroll
        let distanceToTarget = targetPosition - currentScroll
        if (speed < 0) {
            distanceToTarget = currentScroll - targetPosition
        }
        if (distanceToTarget < speed) {
            target.scrollIntoView()
        }
        else {
            window.scrollBy(0, speed)

            if (distanceToTarget == 0) return
            requestAnimationFrame(scroll)
        }
    }
    scroll()
}
export const scrollTo = (target) => {

    scroll.scrollTo(target - 87 , {
        delay: 0,
    })
}

export const scrollIntoBlock = (blocks) => {
    const location = document.location
    const scrollY = window.scrollY
    
    for (const block of blocks) {
        const target = block.current
        if (target) {
            if (
                scrollY >= target.offsetTop - 87 &&
                scrollY <= target.offsetTop + target.clientHeight  - 87 &&
                location.hash !== `#${target.id}`
            ) {
                if (history.pushState) {
                    history.pushState(null, null, `#${target.id}`);
                }
                else {
                    location.hash = `#${target.id}`
                }
            }
        }
    }
}
export const throttle = (func, timeout) => {
    let last
    let timer
   
    return function() {
        const context = this
        const args = arguments
        const now = + new Date()
    
        if (last && now < last + timeout) {
            clearTimeout(timer)
            timer = setTimeout(() =>  {
                last = now
                func.apply(context, args)
            }, timeout)
        }
        else {
            last = now
            func.apply(context, args)
        }
    }
}